import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next'
import { RootState } from '../reducers'
import { clear } from '../reducers/usecase'
import json from '../data/usecase.json'
import * as styles from '../styles/css/usecase.module.css'
import { FiCheck } from 'react-icons/fi'

const Usecase = () => {
  const [data, setData] = useState({})
  const usecase = useSelector((state: RootState) => state.usecase)
  const dispatch = useDispatch()
  const close = (e) => {
    e.preventDefault();
    dispatch(clear())
  };

  const { t } = useTranslation()
  const { language } = useI18next()
  useEffect(() => {
    const item = json[usecase.name]
    if (item) {
      let media;
      if (item['video']) {
        media = <div className={styles.videoWrapper}><iframe className={styles.video} src={item['video'][language]} title={t(item['alt'])} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe></div>
      }
      else {
        media = <img className={`radius w-100 ${styles.detailImg}`} src={item['image']} alt={t(item['alt'])} />
      }
      setData({
        title: item['title'],
        element1: item['element1'],
        element2: item['element2'],
        element3: item['element3'],
        subtitle: item['subtitle'],
        desc: item['desc'],
        media,
        alt: item['alt'],
      })
    }
  }, [usecase, json, language]);
  return (
    <div className={`${styles.modalMask} ${usecase.active ? '' : styles.none}`} onClick={close}>
      <div className={styles.nav}>
        <div className={styles.toolbar}>
          <div className={styles.button} title="Close">
            <object data="/images/icons/close.svg" type="image/svg+xml"></object>
          </div>
        </div>
      </div>
      {/* <div title="previous" className={`${styles.prev} ${styles.slideButtonContainer}`}>
        <div className={styles.slideButton}>
          <object data="/images/icons/left-arrow.svg" type="image/svg+xml"></object>
        </div>
      </div>
      <div title="next" className={`${styles.next} ${styles.slideButtonContainer}`}>
        <div className={styles.slideButton}>
          <object data="/images/icons/right-arrow.svg" type="image/svg+xml"></object>
        </div>
      </div> */}
      <div className={`container`}>
        <div className={styles.wrapper}>
          <div className={`${styles.container} rwt-portfolio-details`}>
            <div className="details-list">
              <div className="row row--30">
                <h3 className={`title ${styles.titletext}`}>{t(data['title'])}</h3>
                <div className={`col-lg-6 ${styles.listcontainer}`}>
                  <div className="content-left">
                    <div className="single-list-wrapper">
                      <ul className="list-icon">
                        <li className={styles.item}><span className="icon"><FiCheck /></span> {t(data[`element1`])} </li>
                        <li className={styles.item}><span className="icon"><FiCheck /></span> {t(data[`element2`])} </li>
                        <li className={styles.item}><span className="icon"><FiCheck /></span> {t(data[`element3`])} </li>
                      </ul>
                    </div>
                    <div className="view-btn mt--50">
                      {/* <a className="btn-default btn-large round" href={`${data.link}`}>{data.linktext}</a> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mt_md--30 mt_sm--30">
                  <div className="content-right">
                    {/* <h5 className="subtitle">{t(data['subtitle'])}</h5> */}
                    <div className={`description ${styles.desctext}`} dangerouslySetInnerHTML={{ __html: t(data['desc']) }}></div>
                  </div>
                </div>
                <div className="thumbnail">
                  {data['media']}
                  {/* <img className={`radius w-100 ${styles.detailImg}`} src={data['image']} alt={t(data['alt'])} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Usecase;