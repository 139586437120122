import React from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { FiLinkedin } from 'react-icons/fi'

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <div className="copyright-area copyright-style-one variation-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-4 col-md-7 col-sm-12 col-12">
            <div className="copyright-left">
              <ul className="ft-menu link-hover">
                <li><a href="/privacy">Privacy Policy</a></li>
                <li><a href="/tac">Terms And Conditions</a></li>
                <li><a href="/#contact">Contact Us</a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-5 mt_sm--20">
            <div className="copyright-center text-center">
              <ul className="social-icon social-default color-lessdark justify-content-center">
                <li><a href="https://www.linkedin.com/company/alphacodecorp" target="_blank"><FiLinkedin /></a></li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12 col-12 mt_md--20 mt_sm--20">
            <div className="copyright-right text-center text-md-right">
              <p className="copyright-text">© {t('title')} {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Copyright
