import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, withPrefix } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Header from './header'
// import Footer from './footer'
import Copyright from './copyright'
import Blury from '../components/blury'
import Usecase from '../components/usecase'

const Layout = ({ children }) => {  
  const { t } = useTranslation()
  const inspection = false
  return inspection ? (
    <div className="error-area ptb--200 ptb_sm--60 ptb_md--80">
      <div className="container">
        <div className="row align-item-center">
          <div className="col-lg-12">
              <div className="error-inner">
                <img src="/images/logo/logo.png" width="250"/>
                <h2>페이지 준비중입니다. <br /> We're making changes!</h2>
                <p className="description">관련자료 준비중에 있습니다. 빠른시일 안에 정상 서비스 하도록 하겠습니다. <br /> The page admin is updating the website for now. Please check again later</p>                
              </div>
          </div>
        </div>
      </div>
    </div>
    )
    : (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta content="website" property="og:type" />
        <meta content="AlphaCode" property="og:site_name" />
        <meta content="//alphacode.ai/" property="og:url" />
        <meta content="ko_KR" property="og:locale" />
        <meta content={t('seoTitle')} property="og:title" />
        <meta content={t('description')} property="og:description" />
        <meta content="/images/icons/icon.png" property="og:image" />
        <meta content="/images/icons/icon.png" property="twitter:image" /> 
        <meta name="keywords" content="alphacode,알파코드,AI,ML,data,vision,b2b" /> 
        <title>{t('title')}</title>
      </Helmet>
      <main className="page-wrapper">
        <Blury />
        <Header headerStyle="overlay" />
        {children}
        {/* <Footer /> */}
        <Copyright />
        <Usecase />
      </main>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default Layout