import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next'

const Language = () => {
  const { language, changeLanguage } = useI18next();
  const koStyle = language === 'ko' ? 'on': ''
  const enStyle = language === 'en' ? 'on': ''
  
  const handleClick = (lang, e) => {   
    e.preventDefault()
    changeLanguage(lang)
  }
  return (
    <div className="lang">
      <a className={koStyle} onClick={(e)=> { handleClick('ko', e); }}>KO</a>
      <span className="division">-</span>
      <a className={enStyle} onClick={(e)=> { handleClick('en', e); }}>EN</a>
    </div>
  )
}
export default Language;