// extracted by mini-css-extract-plugin
export var button = "usecase-module--button--OE94Z";
export var container = "usecase-module--container--H3vYx";
export var desctext = "usecase-module--desctext--GhzgH";
export var detailImg = "usecase-module--detail-img--98-CY";
export var item = "usecase-module--item--DvqmD";
export var listcontainer = "usecase-module--listcontainer--pnD7t";
export var modalMask = "usecase-module--modal-mask--51AwQ";
export var nav = "usecase-module--nav--2Xf-v";
export var next = "usecase-module--next--BKF+e";
export var none = "usecase-module--none--Ag29P";
export var prev = "usecase-module--prev--9Ryse";
export var slideButton = "usecase-module--slide-button--FVCao";
export var slideButtonContainer = "usecase-module--slide-button-container--ojznf";
export var titletext = "usecase-module--titletext--tKuka";
export var toolbar = "usecase-module--toolbar--+gx0c";
export var video = "usecase-module--video--1Bbtj";
export var videoWrapper = "usecase-module--video-wrapper--ImP9l";
export var wrapper = "usecase-module--wrapper--KcKSP";