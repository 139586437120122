import * as React from 'react'
import { Link } from 'gatsby'
import { FiChevronDown } from "react-icons/fi";

const Navigation = () => {
  return (  
    <ul className="mainmenu">
      <li className="has-droupdown">
        <Link to="/">Home</Link>     
      </li>

      <li className="has-droupdown">
        <Link to="/about">About</Link>
      </li>
      
      <li className="has-droupdown">
        <Link to="/#service">Services <FiChevronDown /></Link>
        <ul className="submenu">
          <li><Link to="/offering">Offering</Link></li>
          <li><Link to="/data">Data</Link></li>
          <li><Link to="/vision">Vision</Link></li>
          <li><Link to="/cloud">Cloud</Link></li>
        </ul>
      </li>

      <li className="has-droupdown">
        <Link to="/product">Product</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/#whyus">Why Us</Link>
      </li>

      <li className="has-droupdown">
        <Link to="/#contact">Contact</Link>
      </li>
  </ul>
  )
}

export default Navigation