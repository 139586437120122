import React, { useRef, useState } from "react"
import { FiMenu } from "react-icons/fi";
import Logo from '../components/logo'
import Navigation from '../components/navigation'
import Language from '../components/button/language'
import Appearance from '../components/button/appearance'
import useStickyHeader from '../lib/useStickyHeader'
import Mobile from '../components/mobile'

const Header = ({ headerStyle }) => {
  const [ofcanvasShow, setOffcanvasShow] = useState(false)  
  const ref = useRef<HTMLElement>(null)
  const sticky = useStickyHeader(50)  
  const headerClasses = `header-default ${sticky ? 'sticky' : ''}`
  const onCanvasHandler = () => {
    setOffcanvasShow(prev => !prev);
  }
  return (
    <>
    <header ref={ref} className={`rn-header header-default ${headerStyle} ${headerClasses}`}>
      <div className="container position-relative">
        <div className="row align-items-center row--0">
          <div className="col-lg-3 col-md-6 col-4">
            <Logo 
                image={`/images/logo/logo.png`}
                image2={`/images/logo/logo-dark.png`}
            />
          </div>
          <div className="col-lg-9 col-md-6 col-8 position-static">
            <div className="header-right mr--5">
              <nav className="mainmenu-nav d-none d-lg-block">
                <Navigation />
              </nav>
              <Language />
              <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                      <span className="hamberger-button" onClick={onCanvasHandler}><FiMenu /></span>
                  </div>
              </div>
              <Appearance />
            </div>
          </div>
        </div>
      </div>
    </header>
    <Mobile show={ofcanvasShow} onClose={onCanvasHandler}  />
  </>
  )
}

export default Header