import React, { useEffect } from 'react';
import Logo from "./logo";
import Navigation from './navigation';
import { FiMenu } from "react-icons/fi";

const Mobile = ({show, onClose}) => {
  useEffect(()=> {
    const elements = document.querySelectorAll('.mainmenu .has-droupdown')
    for(const i in elements) {
      if(elements.hasOwnProperty(i)) {
        const element = elements[i] as HTMLElement
        element.querySelector('.submenu')?.classList.toggle("active");
        element.classList.toggle("open");
      }
    }
  }, [])

  return (
    <div className={`popup-mobile-menu ${show ? "active": ""}`}>
      <div className="inner">
        <div className="header-top">
          <Logo 
              image={`/images/logo/logo.png`}
              image2={`/images/logo/logo-dark.png`}
          />
          <div className="close-menu">
            <span className="close-button" onClick={onClose}><FiMenu /></span>
          </div>
        </div>
        <Navigation />
      </div>
    </div>
  )
}
export default Mobile;